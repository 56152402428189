// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//require("@rails/ujs").start()
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("../jquery-ujs");
require("flexslider");
import "bootstrap";
//var jQuery = require('jquery')
// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
//global.$ = global.jQuery = jQuery;
//window.$ = window.jQuery = jQuery;
//import 'bootstrap/dist/js/bootstrap';
//import 'jquery.facebox';

import "@fortawesome/fontawesome-free/js/all";
import flatpickr from "flatpickr";
//import "jquery-slimscroll";
import "select2";
import "select2/dist/css/select2.css";
import "controllers";

window.addEventListener("DOMContentLoaded", () => {
  $(".select2").select2();
});
$(document).on("turbolinks:load", function () {
  $("body").tooltip({
    selector: '[data-toggle="tooltip"]',
    container: "body",
  });

  $("body").popover({
    selector: '[data-toggle="popover"]',
    container: "body",
    html: true,
    trigger: "hover",
  });

  flatpickr(".flatpicker", {
    dateFormat: "Y-m-d",
    disableMobile: true,
  });
  // $('body').popover({
  //   selector: '[data-toggle="tooltip"]',
  //   container: 'body',
  //   html: true,
  //   trigger: 'hover',
  // });
  //$('.accordion').collapse('dispose').collapse();
  $('[data-remote-partial="true"]').each(function () {
    const url = $(this).data("url");
    $.ajax({ url: url });
  });
});
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
